<template>
  <div>
    <el-card class="box-card" style="display: relative">
      <div slot="header" class="clearfix" style="display: flex; align-items: center">
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <el-select
            v-if="!isSeries"
            style="margin: 10px; width: 120px"
            v-model="filterData.tag"
            filterable
            clearable
            placeholder="Network"
          >
            <el-option
              v-for="(item, index) in tags"
              :key="item"
              :label="item"
              :value="index + 1"
            >
            </el-option>
          </el-select>
          <el-select
            style="margin: 10px; width: 120px"
            v-if="!isSeries"
            v-model="filterData.store_id"
            filterable
            clearable
            placeholder="Store"
          >
            <el-option
              v-for="item in stores"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            style="margin: 10px; width: 120px"
            v-model="filterData.theme_id"
            value-key="id"
            filterable
            placeholder="Drop"
            clearable
          >
            <el-option
              v-for="item in themes"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-input
            style="margin: 10px; width: 120px"
            v-if="!isSeries"
            v-model="filterData.brand"
            placeholder="Brand"
           />
          <el-select
            style="margin: 10px; width: 120px"
            v-model="filterData.category_id"
            filterable
            clearable
            placeholder="Categories"
          >
            <el-option
              v-for="item in product_types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-input
            v-model="filterData.min_price"
            placeholder="Min Price"
            type="number"
            clearable
            style="width: 120px; margin: 10px"
          ></el-input>
          <el-input
            v-model="filterData.max_price"
            type="number"
            clearable
            placeholder="Max Price"
            style="width: 120px; margin: 10px; margin-left: 5px"
          ></el-input>

          <el-input
            v-model="filterData.name"
            placeholder="Search"
            clearable
            style="width: 120px; margin: 10px"
          ></el-input>

          <el-button
            type="primary"
            @click="query(1)"
            style="margin: 10px"
            icon="el-icon-search"
            >查询</el-button
          >

          <el-button
            v-if="isSeries"
            type="primary"
            @click="add()"
            style="margin: 10px"
            icon="el-icon-plus"
            >添加</el-button
          >
        </div>
      </div>
      <el-table
        size="mini"
        ref="table"
        v-loading="loading"
        border="true"
        @selection-change="selection_change"
        @sort-change="handleSortChange"
        :data="tableData"
        style="width: 100%; margin-bottom: 10px"
        :cell-style="{paddingTop: '4px', paddingBottom: '0px'}"
      >
        <el-table-column prop="id" type="selection">
        </el-table-column>

        <el-table-column v-if="!isSeries" prop="store.name" label="Store" width="100" show-overflow-tooltip>
           <template slot-scope="scope">
            {{scope.row.store && scope.row.store.name && scope.row.store.name}}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="brand" label="Brand" width="100" show-overflow-tooltip>
           <template slot-scope="scope">
            {{scope.row.brand}}
          </template>
        </el-table-column>
        <el-table-column prop="category_id" label="Category" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            {{categoryNameForId(scope.row.category_id)}}
          </template>
        </el-table-column>
        <el-table-column prop="processed_title" label="Title" width="200">
           <template slot-scope="scope">
            {{scope.row.processed_title}}
          </template>
        </el-table-column>
        <el-table-column prop="currency" label="Curr." width="50">
          <template slot-scope="scope"
            >{{ scope.row.currency }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="Val." width="70">
          <template slot-scope="scope"
            >{{ scope.row.price }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="price_usd" label="USD" width="80">
          <template slot-scope="scope"
            >{{Number(scope.row.price_usd).toFixed(2)}}$
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="Stock" width="55" show-overflow-tooltip>
          <template slot-scope="scope"
            >{{ [0, '0', 'out-of-stock'].includes(scope.row.stock) ? 0 : scope.row.stock }}
          </template>
        </el-table-column>
        <el-table-column prop="size" label="Size" show-overflow-tooltip></el-table-column>
        <el-table-column label="Imgs" width="50">
          <template slot-scope="scope">
            <p style="text-align: center;">
              {{ scope.row.images.length }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Preview" width="80px">
          <template slot-scope="scope">
            <el-image
              style="width: 40px; height: 40px"
              :src="scope.row.images[0]"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="130px">
          <template slot-scope="scope">
            <i v-if="$check(52)" class="el-icon-edit item primary" @click="edit(scope.row)"></i>
              <el-button
                size="mini"
                type="warning"
                @click="translate(scope.$index, scope.row)"
                >T</el-button
              >
            <i v-if="$check(53)" class="el-icon-delete item danger" @click="remove(scope.row.id)"></i>
          </template>
        </el-table-column>
                <el-table-column prop="themes" label="Drop" width="100" show-overflow-tooltip="">
          <template slot-scope="scope">
            <p v-for="(theme, index) in scope.row.themes" :key="index">
              {{ theme.title }}
            </p>
          </template>
        </el-table-column>

          <el-table-column v-if="filterData.theme_id" sortable="custom" prop="sort" width="95" label="Drop #">
          <template slot-scope="scope">
            <el-input
              @change="handleOrderChange(scope.row)"
              v-model="scope.row.themes_info[0].sort"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-row
        style="
          position: absolute;
          bottom: 10px;
          left: 10px;
          right: 19px;
          background: #fff;
          padding: 10px 0;
          z-index: 100;
        "
      >
        <el-col :span="12">
          <el-select
            style="margin-left: 10px; width: 300px"
            v-model="theme_id"
            filterable
            placeholder="选择主题"
          >
            <el-option
              v-for="item in themes"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="add_to_theme()"
            style="margin-left: 10px"
            >Add to drop</el-button
          >
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :page-count="last_page"
            :page-size="30"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog
      center
      fullscreen
      :title="title + 'product'"
      :visible.sync="dialogFormVisible"
    >
        <el-form v-if="dialogFormVisible" :model="form" ref="form" label-width="140px" style="margin: 0px; padding: 0px;">
          <el-row>
            <el-col :span="8" v-if="form.store">
              <el-form-item size="small" label="Shop" prop="store" style="margin-bottom: 0px;">
                <el-select
                  style="width: 100%"
                  v-model="form.store.id"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="query_stores"
                  placeholder="Select a Store"
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                    v-for="(item) in stores"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.product_id">
            <el-col >
              <el-form-item size="small" label="ID" style="margin-bottom: 0px;">
                {{form.product_id}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.product_url">
            <el-col >
              <el-form-item size="small" label="URL" style="margin-bottom: 0px;">
                <div style="line-height: 14px;"><a v-bind:href="form.product_url" target="_blank">{{form.product_url}}</a></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item size="small" label="Brand" prop="brand" style="margin-bottom: 0px;">
                <el-select
                  style="width: 100%"
                  v-model="form.brand"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="Brand name"
                >
                  <el-option
                    :label="item"
                    :value="item"
                    :key="index"
                    v-for="(item, index) in brands"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row>
              <el-col :span="8">
                <el-form-item
                  label="Category"
                  prop="category_id"
                  size="small"
                  :rules="[{ required: true, message: 'Category required' }]"
                  style="margin-bottom: 0px;"
                >
                  <el-select
                    style="width: 100%"
                    allow-create
                    filterable
                    default-first-option
                    v-model="form.category_id"
                  >
                    <el-option
                      v-for="item in product_types"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-tag
                    style="white-space: break-spaces !important; height: auto !important"
                    v-if="form.product_type_en"
                    >{{ form.product_type_en }}</el-tag
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12"
                ><el-form-item
                  label="Title (Intl)"
                  prop="processed_title"
                  size="small"
                  :rules="[{ required: true, message: 'Title required' }]"
                  style="margin-bottom: 0px;"
                >
                  <el-input
                    type="text"
                    v-model="form.processed_title"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
               <el-col :span="12"
                ><el-form-item
                  label="Title (CN)"
                  size="small"
                  prop="cn_title"
                  style="margin-bottom: 0px;"
                >
                  <el-input
                    type="text"
                    v-model="form.cn_title"
                    autocomplete="off"
                  ></el-input>
                  <el-tag
                    style="white-space: break-spaces !important; height: auto !important"
                    v-if="form.processed_title"
                    >原文：{{ form.processed_title }}</el-tag
                  >
                </el-form-item>
              </el-col>
              <el-col :span="12"
                ><el-form-item
                  label="Description (Intl)"
                  prop="processed_description"
                  style="margin-bottom: 0px;"
                  size="small"
                  :rules="[{ required: true, message: 'Description required' }]"
                >
                     <el-checkbox v-model="description_flag" @change="description_flag_change"
                    >Enable rich text</el-checkbox
                  >
                   <quill-editor
                    v-if="description_flag"
                    class="editor"
                    v-model="form.processed_description"
                    ref="myQuillEditor"
                  />

                  <el-input
                    v-else
                    type="textarea"
                    :rows="4"
                    v-model="form.processed_description"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12"
                ><el-form-item
                  label="Description (CN)"
                  prop="cn_description"
                  size="small"
                  style="margin-bottom: 0px;"
                >
                    <el-checkbox v-model="description_flag_cn" @change="description_flag_cn_change"
                    >Enable rich text</el-checkbox
                  >
                   <quill-editor
                    v-if="description_flag_cn"
                    class="editor"
                    v-model="form.cn_description"
                    ref="myQuillEditor"
                  />

                  <el-input
                    v-else
                    type="textarea"
                    :rows="4"
                    v-model="form.cn_description"
                    autocomplete="off"
                  ></el-input>
  
                </el-form-item>
              </el-col>
            </el-row>
             <el-row>
              <el-col :span="8">
                <el-form-item
                  label="Stock"
                  :rules="[{ required: true, message: '库存不能为空' }]"
                  prop="stock"
                  style="margin-bottom: 0px;"
                  size="small"
                >
                  <el-input v-model="form.stock" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="Rare Find"
                    prop="rare_finds"
                    style="margin-bottom: 0px;"
                    size="small"
                  >
                  <el-checkbox v-model="form.rare_finds">
                    Is this a rare find?
                  </el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="Country" style="margin-bottom: 0" size="small">
                  <el-select
                    style="width: 100%"
                    v-model="form.size_options.y"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                  >
                    <el-option
                      v-for="item in ys"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="Size" prop="size" style="margin-bottom: 0px;"
                  size="small">
                  <el-input v-model="form.size" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="Color" prop="color" style="margin-bottom: 0px;"
                  size="small">
                  <el-input v-model="form.color" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="Material" prop="material" style="margin-bottom: 0px;"
                  size="small">
                  <el-input v-model="form.material" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :rules="[{ required: true, message: '价格不能为空' }]"
                  label="Price"
                  prop="price"
                  style="margin-bottom: 0px;"
                  size="small"
                >
                  <span slot="label">Price {{form.currency}}</span>
                  <el-input v-model="form.price" autocomplete="off"></el-input>
                
                </el-form-item>
              </el-col>
              <el-col :span="8"><div style="padding-left: 10px; line-height: 32.5px;" v-if="form.id"><span v-if="form.rmb_price">RMB: {{ form.rmb_price }}</span> <span v-if="form.price_usd && form.currency !== 'USD'">USD: {{ form.price_usd }}</span></div></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"
                ><el-form-item label="Drop" prop="theme"
                  style="margin-bottom: 0px;"
                  size="small"
                >
                  <el-select
                    style="width: 100%"
                    v-model="form.theme_ids"
                    multiple
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    placeholder="Select a drop"
                  >
                    <el-option value="">None</el-option>
                    <el-option
                      :label="item.title"
                      :value="item.id"
                      :key="index"
                      v-for="(item, index) in themes"
                    ></el-option>
                  </el-select> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
               <el-col :span="12"
                ><el-form-item
                  label="SEO Title (Intl)"
                  size="small"
                  prop="meta_title"
                  style="margin-bottom: 0px;"
                >
                  <el-input
                    type="text"
                    v-model="form.meta_title"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
                <el-col :span="12"
                ><el-form-item
                  label="SEO Title (CN)"
                  size="small"
                  prop="cn_meta_title"
                  style="margin-bottom: 0px;"
                >
                  <el-input
                    type="text"
                    v-model="form.cn_meta_title"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
               <el-col :span="12"
                ><el-form-item
                  label="Meta Desc. (Intl)"
                  size="small"
                  prop="meta_description"
                  style="margin-bottom: 0px;"
                >
                  <el-input
                    type="text"
                    v-model="form.meta_description"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
                <el-col :span="12"
                ><el-form-item
                  label="Meta Desc. (CN)"
                  size="small"
                  prop="cn_meta_description"
                  style="margin-bottom: 0px;"
                >
                  <el-input
                    type="text"
                    v-model="form.cn_meta_description"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item
                  size="small" 
                  prop="images"
                  label="Images"
                  :rules="[{ required: true, message: '商品图⽚不能为空' }]"
                >
                  <ImageUpload multiple v-model="form.images"></ImageUpload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col style="text-align: center">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit()">确 定</el-button>
              </el-col>
            </el-row>
          </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from "../common/ImageUpload.vue";
import truncate from "../../lib/truncate.ts"
export default {
  components: { ImageUpload },
  watch: {
    $route(to, from) {
      if (to.params.tag !== from.params.tag) {
        this.isSeries = to.params.tag === 'series'
        delete(this.filterData.theme_id)
        this.filterData.page = 1
        this.theme_id = ""
        this.query(1);
        this.query_product_types();
        this.query_themes();
        this.query_brands();
        this.query_stores();
      }
    }
  },
  data() {
    return {
      tags: [
        "Shopify",
        "Commission Junction",
        "Rakuten Advertising",
        "ShareaSale",
        "Commission Factory",
        "Impact",
        "Skimlinks",
        "Awin",
        "Flex Offers",
        "Sovrn / Commerce",
      ],
      stores: [],
      brands: [],
      theme_id: "",
      themes: [],
      product_types: [],
      inputValue: "",
      inputVisible: false,
      loading: true,
      items: [],
      title: "添加",
      total: 0,
      last_page: 1,
      rule: [],
      sortProp: null,
      sortOrder: null,
      isSeries: this.$route.params.tag === 'series',
      filterData: {
        page: 1
      },
      tableData: [],
      dialogFormVisible: false,
      form: { variants: [] },
      option: null,
      options: [
        { value: "Material", label: "Material" },
        { value: "Size", label: "Size" },
        { value: "Color", label: "Color" },
      ],
      labels: { Material: "Material", Size: "Size", Color: "Color" },
      selections: null,
      xs: ["XXXS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "4XL"],
      ys: ["CN", "FR", "IT", "US", "UK"],
      description_flag: false,
      description_flag_cn: false
    };
  },

  created() {
    if (this.$route.query.theme_id) {
      this.filterData.theme_id = parseInt(this.$route.query.theme_id)
      this.theme_id = parseInt(this.$route.query.theme_id)
    }
    this.query(1);
    this.query_product_types();
    this.query_themes();
    this.query_brands();
    this.query_stores();
  },

  methods: {
    truncate(string, n) {
      return truncate(string, n)
    },
    get_tag(tag) {
      return this.tags[tag];
    },
    selection_change(selections) {
      this.selections = selections;
    },
    categoryNameForId(id) {
      let type = this.product_types.find(p => parseInt(p.id) === parseInt(id))
      return type && type.name || ''
    },
    handleSortChange({order, prop}) {
      let _prop = prop
      if (_prop === 'store.name') _prop = 'store'
      if (_prop === 'sort') _prop = 'drop'
      if (_prop === 'price_usd') _prop = 'price'
      if (order) {
        this.sortProp = _prop
        this.sortOrder = order
      } else {
        this.sortProp = null
        this.sortOrder = null
      }
      this.query(1)
    },
    handleOrderChange(row) {
      this.$http
        .post("/admin/theme/products/save", {
          theme_id: parseInt(row.themes_info[0].theme_id),
          product_id: parseInt(row.themes_info[0].product_id),
          sort: `${row.themes_info[0].sort}`,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "Product Saved",
              type: "success",
            });
          }
        });
    },
    translate(index, info) {
      this.$http.post("/admin/product/translate", { id: info.id }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "Translation Saved",
            type: "success",
          });
          res.data.data.theme_ids = res.data.data.theme_ids || []
          this.$set(this.tableData, index, res.data.data);
        }
      });
    },
    add_to_theme() {
      if (!this.theme_id) {
        this.$message({
          message: "请选择主题",
          type: "warning",
        });
        return;
      }

      if (!this.selections || this.selections.length == 0) {
        this.$message({
          message: "请选择商品",
          type: "warning",
        });
        return;
      }

      let product_ids = [];
      this.selections.forEach((item) => {
        product_ids.push(item.id);
      });
      this.$http
        .post("/admin/theme/products/save", {
          theme_id: this.theme_id,
          product_ids: product_ids,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$refs["table"].clearSelection();
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        });
    },
    remove_option(index) {
      this.form.variants.splice(index, 1);
    },
    get_label(key) {
      return this.labels[key] ? this.labels[key] : key;
    },
    change_opiton() {
      let that = this;
      this.form.variants.forEach((item) => {
        for (let key in item) {
          if (key != "stock" && key != "price") {
            if (that.form.options.indexOf(key) == -1) {
              delete item[key];
            }
          }
        }
      });
    },
    add_row() {
      this.form.variants.push({});
    },

    chSort(info) {
      this.$http
        .post("/admin/product/save", { id: info.id, sort: info.sort })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
    },

    chStatus(info) {
      this.$http
        .post("/admin/product/save", { id: info.id, status: info.status })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    pageChange(page) {
      this.query(page, true);
    },
    query(page, scrollToTop = false) {
      if (page) this.filterData.page = page;
      let sort_by
      if (this.sortProp && this.sortOrder) {
        sort_by = `${this.sortOrder === "ascending" ? '' : '-'}${this.sortProp}`
      }
      this.loading = true;
      this.$http
        .post("/admin/store/products", {
          ...this.filterData,
          tag: this.isSeries ? 0 : 1,
          items_per_page: 30,
          sort_by
        }).then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.tableData = res.data.data;
            this.tableData = this.tableData.map(t => ({
              ...t,
              rare_finds: t.rare_finds === 1 ? true : false,
              processed_title: t.processed_title || t.title,
              processed_description: t.processed_description || t.body_html
            }))
            this.total = res.data.total;
            this.last_page = res.data.last_page;
            if (scrollToTop) {
              document.getElementById("home-content").scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }
          }
        })
        .catch(() => {});
    },

    query_brands(query) {
      this.$http
        .post("/admin/store/brands", { tag: this.isSeries ? 0 : 1, name: query })
        .then((res) => {
          if (res.status == 200) {
            this.brands = res.data.data;
          }
        })
        .catch(() => {});
    },

    query_stores() {
      this.$http
        .post("/admin/store/list", { status: 1 })
        .then((res) => {
          if (res.status == 200) {
            this.stores = res.data.data;
          }
        })
        .catch(() => {});
    },

    query_themes(name) {
      this.$http
        .post("/admin/theme/list", { name: name, tag: this.isSeries ? 0 : 1 })
        .then((res) => {
          if (res.status == 200) {
            this.themes = res.data.data;
          }
        })
        .catch(() => {});
    },

    query_product_types() {
      this.$http
        .post("/admin/product/types", { tag: this.isSeries ? 0 : 1, lang: 'en'})
        .then((res) => {
          if (res.status == 200) {
            this.product_types = Object.entries(res.data).map(([key, value]) => {
              return {
                id: parseInt(key),
                name: value
              }
            });
          }
        })
        .catch(() => {});
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/product/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    themeSelected(row) {
      return row.themes_info && row.themes_info.length
    },

    edit(row) {
      this.title = "Edit ";
      this.form = JSON.parse(JSON.stringify(row));
      if (!this.form.size_options) {
        this.form.size_options = {
          x: [],
          y: [],
          d: {},
        };
      }
      this.dialogFormVisible = true;
    },

    add() {
      this.form = { variants: [], size_options: [], theme_ids: []};
      this.title = "Add ";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/store/product/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.editor {
  line-height: normal !important;
  height: 200px;
  margin-bottom: 20px;
}
.primary {
  color: #409eff;
}

.item {
  font-size: 20px;
  margin: 0 5px;
  cursor: pointer;
}
.warning {
  color: #e6a23c;
}

.danger {
  color: #f56c6c;
}
</style>
